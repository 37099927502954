import React from 'react'
import ReactDOM from 'react-dom/client'
import thunk from "redux-thunk"
import App from './App'
import "./assets/styles.css"
import { createGlobalStyle } from 'styled-components'
import { applyMiddleware, compose, createStore } from "redux"
import { rootReducer } from "./redux/rootReducer"
import { Provider } from 'react-redux'
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-blue/theme.css";  // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import { PrimeReactProvider } from 'primereact/api'

export const Global = createGlobalStyle`
  * {
    //@imports url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
    font-family: Montserrat, sans-serif;
  }

  a {
    color: black;
    text-decoration: none;
  }
`

const store = createStore(rootReducer, compose(
  applyMiddleware(thunk)
))


const root = ReactDOM.createRoot(document.getElementById('root'))


root.render(
  <React.StrictMode>
    <Global />
    <PrimeReactProvider>

      <Provider store={store}>
          <App />
      </Provider>
    </PrimeReactProvider>
  </React.StrictMode>
)


import React from 'react'
import styled from 'styled-components'
import Registry from './Registry'
import {connect, useDispatch, useSelector} from 'react-redux'
import {StyledHr} from "./commonStyledComponents/StyledFormWidgets"
import {Link} from "react-router-dom"
import {fetchRegistries} from "../redux/actions"
import { SET_REGISTRY_LABEL } from '../redux/types'

const StyledRegistriesWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  display: block;
  justify-content: center;
  text-align: center;
  background-color: #f5f9ff;
  border-radius: 15px;
  border: solid 1px rgb(179, 204, 255);
`
const Header = styled.div`
  h4 {
    padding: 1rem;
    font-weight: bolder;
  }
`


const Registries = () => {
    const asyncRegistries = useSelector(state => state.registries.fetchedRegistries)
    const dispatch = useDispatch()
    if (!asyncRegistries.length) {
        dispatch(fetchRegistries())
    }
    return (
        <StyledRegistriesWrapper>
            <Header>
                <h4>Выберите реестр</h4>
                <StyledHr/>
            </Header>
            {asyncRegistries.map((object, i) => (
                <Link to={`/registries/${parseInt(object.name)}`} key={object.name}>
                    <Registry object={object} key={i}/>
                </Link>
            ))}

        </StyledRegistriesWrapper>
    )
}
const mapStateToProps = state => {
    return {
        asyncRegistries: state.registries.registries,
        searchedItems: state.registrySearchableFields.searchedItems
    }
}
export default connect(mapStateToProps, null)(Registries)

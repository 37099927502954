import React from "react";

import { StyledHr, StyledResultsTable, StyledRow, SuccessButton } from "../commonStyledComponents/StyledFormWidgets";

const SGR = ({documentInfo}) => {
    return (
        <table style={{ marginTop: 20, marginBottom: 20, maxWidth: 400 }}>
            <StyledRow>
                <th colSpan="2">Основная информация</th>
            </StyledRow>
            <StyledRow>
                <td>Регистрационный номер документа</td>
                <td>{documentInfo?.registerNumber}</td>
            </StyledRow>
            <StyledRow>
                <td>Дата регистрации</td>
                <td>{documentInfo?.dateFrom}</td>
            </StyledRow>
            <StyledRow>
                <td>Действует по</td>
                <td>{documentInfo?.dateTo}</td>
            </StyledRow>
            <StyledRow>
                <td>Номер БСО</td>
                <td>{documentInfo?.printingNumber}</td>
            </StyledRow>
            <StyledRow>
                <td>Наименование изготовителя</td>
                <td>{documentInfo?.licensee.translations.ru.fullName}</td>
            </StyledRow>
            <StyledRow>
                <td>Наименование заявителя</td>
                <td className="wrap">{documentInfo?.trader.translations.ru.fullName}</td>
            </StyledRow>
            <StyledRow>
                <td>ФИО руководителя</td>
                <td>
                    {documentInfo?.agencyHead.firstName} {documentInfo?.agencyHead.surName}
                </td>
            </StyledRow>
        </table>
    );
};

export default SGR;

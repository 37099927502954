import React from "react"
import Registries from "./components/Registries"
import styled from "styled-components"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import HomePage from "./components/pages/HomePage"
import RegistryPage from "./components/pages/RegistryPage"
import Layout from "./components/Layout"
import DocumentDetailPage from "./components/pages/DocumentDetailPage"
import "./assets/styles.css"

const StyledApp = styled.div`
  margin: 0 auto;
`


function App() {

    return (
        <>
            <StyledApp>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<HomePage />} />
                            <Route path="/registries" element={<Registries />} />
                            <Route path="/registries/:id" element={<RegistryPage />} />
                            <Route path="/registry/:documentId/:id" element={<DocumentDetailPage />} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </StyledApp>

        </>

    )
}

export default App

import SearchForm from "../SearchForm"
import React, { useEffect } from "react"
import SearchedResults from "../SearchedResults"
import {connect, useDispatch, useSelector} from "react-redux"
import {StyledHr} from "../commonStyledComponents/StyledFormWidgets"
import Pagination from "../Pagination"
import {useParams} from "react-router-dom"
import {fetchRegistries, setRegistryId} from "../../redux/actions"

const RegistryPage = () => {
    let dispatch = useDispatch()
    let {id} = useParams()
    const registries = useSelector(state => state.registries.fetchedRegistries)

    useEffect(() => {
        if(registries.length < 1){
            dispatch(fetchRegistries())
        }
    }, [registries])

    localStorage.setItem('id', JSON.stringify)

    const registryLabel = registries?.filter(el => parseInt(el.name) == id)[0]?.label
    dispatch(setRegistryId(id))
    return (
        <div>
            
            <div>{registryLabel}</div>
            <StyledHr/>
            <SearchForm registryId={id}/>
            <SearchedResults/>
            <Pagination/>
        </div>
    )
}
export default RegistryPage
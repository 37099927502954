import {
    FETCH_REGISTRIES,
    FETCH_REGISTRY_FIELDS,
    GET_DOCUMENT_INFO,
    HIDE_LOADER,
    HIDE_REGISTRIES,
    SEARCH_REGISTRY, SET_CURRENT_PAGE, SET_REGISTRY_ID, SET_REGISTRY_LABEL, SET_SEARCH_FORM_STATE,
    SET_SERVER_ERRORS,
    SHOW_LOADER,
    UNSET_SERVER_ERRORS,
    VIEW_EXTENDED_FIELDS
} from "./types"

import API from "../api/API"

export function showLoader() {
    return {
        type: SHOW_LOADER
    }
}

export function hideLoader() {
    return {
        type: HIDE_LOADER
    }
}

export function hideRegistries() {
    return {
        type: HIDE_REGISTRIES
    }
}


export function fetchRegistries() {
    return async dispatch => {
        const response = await API.getRegistries("ru")
        dispatch({type: FETCH_REGISTRIES, payload: response})
    }
}

export function fetchRegistrySearchableFields(registryId, registryLabel) {
    return async dispatch => {
        dispatch(showLoader())
        const response = await API.getRegistryFields(registryId, 'ru')
        dispatch({
                type: FETCH_REGISTRY_FIELDS,
                payload: response.searchable,
                registryId: registryId,
                registryLabel: registryLabel,
            })
            console.log(registryLabel)
            if(registryLabel && registryLabel.length > 0)
                localStorage.setItem('registryLabel', JSON.stringify({registryLabel}) )
        dispatch(setCurrentPage(1))
        dispatch(hideLoader())

    }
}

export function setSearchFormState(data){
    return {
        type: SET_SEARCH_FORM_STATE,
        payload: data
    }
}

export function searchRegistries(registryId, data, offset, limit) {
    return async dispatch => {
        dispatch(showLoader())
        const response = await API.searchRegistryByFields(registryId, 'ru', data, offset, limit)
        dispatch({type: SEARCH_REGISTRY, items: response.items, total: response.total})
        dispatch(hideLoader())

    }
}

export function getDocumentInfoById(documentId) {
    return async dispatch => {
        dispatch(showLoader())
        const response = await API.getDocumentInfoById(documentId, "ru")
        // if(response.status && response.status === "500"){
        //     dispatch(setServerErrors())
        // }
        dispatch({type: GET_DOCUMENT_INFO, payload: response})
        dispatch(hideLoader())
    }
}

export function viewExtendedFields() {
    return {
        type: VIEW_EXTENDED_FIELDS
    }
}

export function setServerErrors() {
    return {
        type: SET_SERVER_ERRORS
    }
}

export function unSetServerErrors() {
    return {
        type: UNSET_SERVER_ERRORS
    }
}
export function setCurrentPage(page){
    return{
        type: SET_CURRENT_PAGE,
        payload: page
    }
}
export function setRegistryId(registryId){
    return{
        type: SET_REGISTRY_ID,
        payload: registryId
    }
}
export function setRegistryLabel(registryLabel){
    return {
        type: SET_REGISTRY_LABEL,
        payload: registryLabel
    }
}
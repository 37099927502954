import React, { useEffect } from "react";
import { getDocumentInfoById } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { StyledHr, StyledResultsTable, StyledRow, SuccessButton } from "../commonStyledComponents/StyledFormWidgets";
import Products from "../Products";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SGR from "./SGR";

export default function DocumentDetailPage() {
    const dispatch = useDispatch();
    const { documentId, id } = useParams();
    const { documentInfo, registryId } = useSelector((state) => state.registrySearchableFields);
    // const id = JSON.parse(localStorage.getItem('id'))
    if (Object.keys(documentInfo).length === 0) {
        dispatch(getDocumentInfoById(documentId));
    } else {
        return (
            <>
                <Link to={"/registries/" + id}>
                    <SuccessButton>Назад к реестру</SuccessButton>
                </Link>
                {/* <DataTable value={[documentInfo]}>
                    <Column header="Серия бланка" field="blankNumber" />
                    <Column header="№ по реестру" field="registerNumber" />
                    <Column
                        header="Кому выдана"
                        field={(e) => e.trader.shortName ?? e.trader?.translations?.ru?.fullName}
                    />
                    <Column header="Адрес лицензиата" field={(e) => e.trader.translations.ru.registeredFullAddress} />
                    <Column
                        header="Регистрационный № свидетельства"
                        field={(e) => {
                            return e.licensee.patentIssuer.split("\n")[0];
                        }}
                    />
                    <Column header="ИНН" field={(e) => e.trader.inn} />
                    {registryId == 15 ? (
                        <Column
                            header="Вид деятельности"
                            field={(e) => "Хранение и розничная реализация алкогольной продукции"}
                        />
                    ) : (
                        <></>
                    )}
                    <Column
                        header="Адрес осуществления деятельности"
                        field={(e) =>
                            e.trader.branches[0]?.translations?.ru?.actualFullAddress +
                            "," +
                            "\n" +
                            e.trader.branches[0]?.shortName
                        }
                    />
                    <Column header="Срок действия" field={(e) => e.dateFrom.split(" ")[0] + " - бессрочно"} />
                    <Column />
                </DataTable> */}

                <StyledResultsTable>
                    {id != 3 ? (
                        <table style={{ marginTop: 20, marginBottom: 20, maxWidth: 400 }}>
                            <StyledRow>
                                <th colSpan="2">Основная информация</th>
                            </StyledRow>
                            <StyledRow>
                                <td>Регистрационный номер документа</td>
                                <td>{documentInfo.registerNumber}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Дата регистрации</td>
                                <td>{documentInfo.dateFrom}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Действует по</td>
                                <td>{documentInfo.dateTo}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Статус</td>
                                <td>{documentInfo.status.translations.ru.name}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Дата изменения статуса</td>
                                <td>{documentInfo.statusChangeDate}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Причина изменения статуса</td>
                                <td>{documentInfo.statusChangeReason}</td>
                            </StyledRow>
                            <StyledRow>
                                <th colSpan="2">Код документа оценки соответствия</th>
                            </StyledRow>
                            <StyledRow>
                                <td>Код</td>
                                <td>{documentInfo.conformityCode.code}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Код ЕЭАК</td>
                                <td>{documentInfo.conformityCode.eeuCode}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Саб-код ЕЭАК</td>
                                <td>{documentInfo.conformityCode.eeuSubcode}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Название</td>
                                <td>{documentInfo.conformityCode.translations.ru.name}</td>
                            </StyledRow>
                            <StyledRow>
                                <th colSpan="2">Общие сведения</th>
                            </StyledRow>
                            {/* 
                        <StyledRow>
                            <td>Регламентные документы</td>
                            <td>{documentInfo?.contractInfo}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>Область применения продукции</td>
                            <td> </td>
                        </StyledRow>
                        <StyledRow>
                            <td>Соответствие требованиям</td>
                            <td> </td>
                        </StyledRow>
                        <StyledRow>
                            <td>Информация на этикетке</td>
                            <td> </td>
                        </StyledRow> 
                        <StyledRow>
                            <td>Протоколы исследований</td>
                            <td>{documentInfo?.testProtocols}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>Гигиеническая характеристика</td>
                            <td>{documentInfo?.hygienicCharacteristics}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>Показатели</td>
                            <td> </td>
                        </StyledRow>
                        <StyledRow>
                            <td>Лабораторные исследования</td>
                            <td>{documentInfo?.laboratoryResearches}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>Гигиенический норматив</td>
                            <td> </td>
                        </StyledRow>
                        <StyledRow>
                            <td>Наименование уполномоченного органа</td>
                            <td> </td>
                        </StyledRow>
                            */}
                            <StyledRow>
                                <td>Дата создания</td>
                                <td>{documentInfo.createdOn}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Дата изменения</td>
                                <td>{documentInfo.modifiedOn}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Дата отправки в ЕЭК</td>
                                <td>{documentInfo.sendToEEUDate}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Код вида объекта технического регулирования</td>
                                <td>{documentInfo.productionType}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Признак включения продукции в единый перечень</td>
                                <td>{documentInfo.singleListProductIndicator === true ? "Есть" : "Нет"}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Код вида документа об оценке соответствия</td>
                                <td>{documentInfo.certificateSchemaCode}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Дата выдачи</td>
                                <td>{documentInfo.issueDate}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Номер БСО</td>
                                <td>{documentInfo.printingNumber}</td>
                            </StyledRow>

                            <StyledRow>
                                <th colSpan="2">Сведения об изготовителе</th>
                            </StyledRow>
                            <StyledRow>
                                <td>Наименование изготовителя</td>
                                <td>{documentInfo.licensee.translations.ru.fullName}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Страна изготовителя</td>
                                <td>{documentInfo.licensee.countryCode}</td>
                            </StyledRow>

                            {/* <StyledRow>
                            <td>Адрес изготовителя</td>
                            <td> </td>
                        </StyledRow>
                        <StyledRow>
                            <td>Наименование филиала изготовителя</td>
                            <td> </td>
                        </StyledRow>
                        <StyledRow>
                            <td>Страна филиала</td>
                            <td> </td>
                        </StyledRow>
                        <StyledRow>
                            <td>Адрес филиала</td>
                            <td> </td>
                        </StyledRow> */}

                            <StyledRow>
                                <th colSpan="2">Сведения об органе выдавшем документ</th>
                            </StyledRow>
                            <StyledRow>
                                <td className="wrap">Полное наименование</td>
                                <td>{documentInfo.agency.translations.ru.fullName}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Короткое наименование</td>
                                <td>{documentInfo.agency.shortName}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>ИНН</td>
                                <td>{documentInfo.agency.inn}</td>
                            </StyledRow>

                            <StyledRow>
                                <td>ОКПО</td>
                                <td>{documentInfo.agency.okpo}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>ФИО руководителя</td>
                                <td>
                                    {documentInfo.agencyHead.firstName} {documentInfo.agencyHead.surName}
                                </td>
                            </StyledRow>
                            {/* <StyledRow>
                            <td>Электронная почта</td>
                            <td>{documentInfo.agency.email}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>Почтовый индекс</td>
                            <td>{documentInfo.agency.registeredPostalIndex}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>Код страны</td>
                            <td>{documentInfo.agency.registeredCountryCode}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>Идентификатор органа по оценке соответствия</td>
                            <td>{documentInfo.agency.agencyCode}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>Номер документа, подтверждающего аккредитацию</td>
                            <td>{documentInfo.agency.accreditationDocumentId}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>accreditationCertificateNumber</td>
                            <td>{documentInfo.agency.accreditationCertificateNumber}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>Дата начало документа, подтверждающего аккредитацию</td>
                            <td>{documentInfo.agency.accreditationDocumentStartDate}</td>
                        </StyledRow>

                        <StyledRow>
                            <td>registeredAte</td>
                            <td>{documentInfo.agency.translations.ru.registeredAte}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>Юридеческий адрес в текстовом формате</td>
                            <td>{documentInfo.agency.translations.ru.registeredFullAddress}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>actualAte</td>
                            <td>{documentInfo.agency.translations.ru.actualAte}</td>
                        </StyledRow>
                        <StyledRow>
                            <td>Фактический город</td>
                            <td>{documentInfo.agency.translations.ru.actualCity}</td>
                        </StyledRow>
                        <StyledRow>
                            <th colSpan="2">Руководитель органа</th>
                        </StyledRow>
                        <StyledRow>
                            <td>Полное имя</td>
                            <td>{documentInfo.agencyHead.fullName}</td>
                        </StyledRow> */}
                            <StyledRow>
                                <th colSpan="2">Сведения о заявителе</th>
                            </StyledRow>

                            <StyledRow>
                                <td>Полное наименование</td>
                                <td className="wrap">{documentInfo.trader.translations.ru.fullName}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Короткое наименование</td>
                                <td className="wrap">{documentInfo.trader.shortName}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>ОКПО</td>
                                <td>{documentInfo.trader.okpo}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>ИНН</td>
                                <td>{documentInfo.trader.inn}</td>
                            </StyledRow>
                            <StyledRow>
                                <th colSpan="2">Юридический адрес заявителя</th>
                            </StyledRow>
                            <StyledRow>
                                <td>Юридический адрес в текстовом формате</td>
                                <td className="wrap">{documentInfo.trader.translations.ru.registeredFullAddress}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Код территории</td>
                                <td>{documentInfo.trader.registeredPlaceCode}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Регион</td>
                                <td>{documentInfo.trader.translations.ru.registeredRegion}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Район</td>
                                <td>{documentInfo.trader.translations.ru.registeredDistrict}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Город</td>
                                <td>{documentInfo.trader.translations.ru.registeredCity}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Улица</td>
                                <td>{documentInfo.trader.translations.ru.registeredStreet}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Номер дома</td>
                                <td>{documentInfo.trader.registeredBuilding}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Номер помещения</td>
                                <td>{documentInfo.trader.registeredApartment}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Почтовый индекс</td>
                                <td>{documentInfo.trader.registeredPostalIndex}</td>
                            </StyledRow>
                            <StyledRow>
                                <th colSpan="2">Фактический адрес заявителя</th>
                            </StyledRow>
                            <StyledRow>
                                <td>Код территории</td>
                                <td>{documentInfo.trader.actualPlaceCode}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Регион</td>
                                <td>{documentInfo.trader.translations.ru.actualRegion}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Район</td>
                                <td>{documentInfo.trader.translations.ru.actualDistrict}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Город</td>
                                <td>{documentInfo.trader.translations.ru.actualCity}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Улица</td>
                                <td>{documentInfo.trader.translations.ru.actualStreet}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Номер дома</td>
                                <td>{documentInfo.trader.actualBuilding}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Номер помещения</td>
                                <td>{documentInfo.trader.actualApartment}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Почтовый индекс</td>
                                <td>{documentInfo.trader.actualPostalIndex}</td>
                            </StyledRow>
                        </table>
                    ) : (
                        <SGR documentInfo={documentInfo} />
                    )}
                </StyledResultsTable>

                {registryId != 15 ? <Products products={documentInfo.products} /> : <></>}
            </>
        );
    }
}

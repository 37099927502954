import {FETCH_REGISTRY_FIELDS, GET_DOCUMENT_INFO, SEARCH_REGISTRY, SET_CURRENT_PAGE, SET_REGISTRY_ID, SET_REGISTRY_LABEL, SET_SEARCH_FORM_STATE} from "../types"

const initialState = {
    searchableRegistryFields: [],
    fetchedSearchableRegistryFields: [],
    searchedItems: [],
    registryId: 1,
    registryLabel: '',
    documentInfo: {},
    currentPage: 1,
    perPage: 10,
    totalCount:0,
    searchFormState: {},
}

export const registrySearchableFieldsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REGISTRY_FIELDS:
            return {
                ...state,
                fetchedSearchableRegistryFields: action.payload,
                registryId: action.registryId,
                registryLabel: action.registryLabel,
                searchedItems: [],
            }
        case SET_REGISTRY_ID:
            return {
                ...state,
                registryId: action.payload
            }
        case GET_DOCUMENT_INFO:
            return{
                ...state,
                documentInfo: action.payload,
                registryLabel: action.payload.registerNumber
            }
        case SEARCH_REGISTRY:
            return {
                ...state,
                searchedItems: action.items,
                totalCount: action.total,
            }
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            }
        case SET_SEARCH_FORM_STATE:
            return {
                ...state,
                searchFormState: action.payload
            }
        case SET_REGISTRY_LABEL:
            return {
                ...state,
                registryLabel: action.payload
            }
        default:
            return state
    }
}
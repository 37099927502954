import SearchedRegistry from "./SearchedResult";
import React from "react";
import { connect, useSelector } from "react-redux";
import { StyledResultsTable, StyledResultsWrapper } from "./commonStyledComponents/StyledFormWidgets";

function SearchedResults() {
    const searchedItems = useSelector((state) => state.registrySearchableFields.searchedItems);
    const [sortConfig, setSortConfig] = React.useState({});

    if (!searchedItems?.length) {
        return <StyledResultsWrapper>Данных нет</StyledResultsWrapper>;
    }

    let sortedProducts = [...searchedItems];

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });
    };
    if (sortConfig !== null) {
        sortedProducts.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === "ascending" ? 1 : -1;
            }
            return 0;
        });
    }

    if (searchedItems.length === 0) {
        return (
            <>
                <StyledResultsWrapper>
                    <p>Поиск не дал результатов</p>
                </StyledResultsWrapper>
            </>
        );
    }

    return (
        <div style={{ overflowX: "scroll", width: "100%" }}>
            <StyledResultsTable>
                <tbody>
                    <tr>
                        <th>Действия</th>
                        <th onClick={() => requestSort("agency")}>Орган выдавший документ:</th>
                        <th onClick={() => requestSort("trader")}>Заявитель:</th>
                        <th className="wrap" onClick={() => requestSort("registerNumber")}>
                            Регистрационный номер документа:
                        </th>
                        <th onClick={() => requestSort("dateFrom")}>Действует с:</th>
                        <th>Статус документа:</th>
                    </tr>
                    {sortedProducts.map((item) => (
                        <SearchedRegistry key={item.documentId} object={item} />
                    ))}
                </tbody>
            </StyledResultsTable>
        </div>
    );
}
export default connect(null, null)(SearchedResults);

import {connect, useDispatch, useSelector} from "react-redux"
import styled from "styled-components"
import {NavLink, Outlet} from "react-router-dom"
import React from "react"
import {fetchRegistries} from "../redux/actions"

const StyledNavbar = styled.nav`
  margin-bottom: 1rem;
  height: 70px;
  margin-top: 1rem;
  border-radius: 15px;
  border: solid 1px rgb(179, 204, 255);
  padding: 25px;
  background-color: #f5f9ff;

  .navbar-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  span {
    margin-right: 10px;
    margin-left: 10px;
  }

  a {
    text-decoration: none;
  }

  .active {
    font-weight: bolder;
  }
`

const Layout = () => {
    const dispatch = useDispatch()
    const currentPage = useSelector(state => state.registrySearchableFields.currentPage)
    const totalCount = useSelector(state => state.registrySearchableFields.totalCount)
    const perPage = useSelector(state => state.registrySearchableFields.perPage)

    return (
        <>
            <StyledNavbar>
                <span className="navbar-item">
                    <NavLink to="/">
                        Главная
                    </NavLink>
                </span>
                <span className="navbar-item">
                    <NavLink
                        onClick={() => dispatch(fetchRegistries(currentPage, perPage))}
                        to="/registries"
                    >
                        Реестры
                    </NavLink>
                </span>
            </StyledNavbar>
            <Outlet/>
        </>
    )
}
export default connect(null, null)(Layout)

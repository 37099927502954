import styled from "styled-components"

export const StyledPaginationWrapper = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap'); /*
  #################################################
  This is part of the UI framework being developed.
  #################################################
  */
  //---------
  $gray0: #F6F8FF;
  $primary: #636983;
  $borderRadius: 40px;

  $active: linear-gradient(45deg, #719aff, #80a5fc);
  $width: 300px;

  margin-top: 1rem;
  margin-bottom: 1rem;

  .title {
    text-align: center;
    padding: 40px 0 0 0;
    font-size: 40px;
    font-weight: 300;
    color: $primary;
  }

  .sub-title {
    font-size: 12px;
    color: $primary;
    font-weight: 300;
    margin: 20px 0;
  }

  .wrapper {
    background-color: $gray0;
    width: 100%;
    min-height: 100vh;

    .line {
      width: 50%;
      margin: 50px auto 0 auto;
      height: 1px;
      background-color: #c3d2e3;
    }
  }

  //---------------------
  .wrapper-pgn {
    width: $width;
    margin: 0 auto;
  }

  .pgn {
    width: $width;
    background-color: white;
    border-radius: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 25px 0 rgb(0 0 0 / 4%);

    .page {
      height: 40px;
      width: 40px;
      border-radius: 40px;
      flex-basis: 40px;
      flex-shrink: 0;
      text-align: center;
      box-sizing: border-box;
      padding: 10px 0;
      color: #777;
      cursor: pointer;
      font-weight: 600;
      transition: all .3s;

      &.active {
        background: linear-gradient(45deg, #4173ff, #0088c4);
        color: white;
      }

      &:hover:not(.active) {
        color: #4173ff;
      }
    }

    .pages {
      display: flex;
      justify-content: start;
      overflow-x: auto;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        height: 0;
      }
    }
  }

  .mouse {
    position: relative;
    top: -20px;
    left: 51%;
    width: 100px;

    .arrow {
      position: relative;
      top: -50px;
      right: -10px;
    }

    img {
      width: 60px;
    }
  }
`
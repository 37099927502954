import React, { useEffect, useState } from "react";
import { DangerButton, Input, Select, StyledCheckbox, StyledControl, StyledSearchForm, SuccessButton } from "./commonStyledComponents/StyledFormWidgets";
import { connect } from "react-redux";
import { fetchRegistrySearchableFields, hideRegistries, searchRegistries, setCurrentPage, setSearchFormState, viewExtendedFields } from "../redux/actions";
import { Grid } from "styled-css-grid";

const SearchForm = (props) => {
    const [formFields, setFormFields] = useState({});
    useEffect(() => {
        if(props && props.length !== 0){
            props.searchRegistries(props.registryId, {}, props.currentPage, props.perPage);
            props.fetchRegistrySearchableFields(props.registryId, props.registryLabel);
        }
    }, []);

    const validateData = (data) => {
        if (!data) {
            return {};
        }
        for (let [key, value] of Object.entries(data)) {
            if (typeof value === "string") {
                data[key] = value.trim();
            }

            if (["dateFrom", "dateTo", "createdOn", "modifiedOn"].includes(key)) {
                data[key] = new Date(value).getTime();
            }
        }
        return data;
    };

    const clearSearchForm = (event) => {
        document.getElementById("search-form").reset();
        setFormFields({});
    };

    const showExtendedFields = () => {
        props.showExtendedFields();
    };

    const submitHandler = (event) => {
        event.preventDefault();
        let validatedData = validateData(formFields);
        let registryId = props.registryId;

        props.setSearchFormState(validatedData);
        props.searchRegistries(registryId, validatedData, 0, props.perPage);
    };

    const changeInputHandler = (event) => {
        event.persist();
        props.setSearchFormState((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
        setFormFields((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };


    if (!props.fetchedFields?.length) {
        return <>Нет фильтров</>;
    }
    let fields = props.isShowExtendedFields
        ? props.fetchedFields
        : props.mainFields;

    return (
        <StyledSearchForm id="search-form" onSubmit={submitHandler}>
            <Grid columns="repeat(auto-fit,minmax(350px,3fr))">
                {fields.map((field, i) => {
                    switch (field.type) {
                        case "UUID":
                            return (
                                <StyledControl key={field.name}>
                                    <label>
                                        {field.label}:
                                        <br />
                                        <Input
                                            onChange={changeInputHandler}
                                            name={field.name}
                                            type="number"
                                        />
                                    </label>
                                </StyledControl>
                            );
                        case "STRING":
                            return (
                                <StyledControl key={field.name}>
                                    <label>
                                        {field.label}:
                                        <br />
                                        <Input
                                            onChange={changeInputHandler}
                                            name={field.name}
                                            type="text"
                                        />
                                    </label>
                                </StyledControl>
                            );
                        case "DATETIME":
                            return (
                                <StyledControl key={field.name}>
                                    <label>
                                        {field.label}:
                                        <br />
                                        <Input
                                            onChange={changeInputHandler}
                                            name={field.name}
                                            type="date"
                                        />
                                    </label>
                                </StyledControl>
                            );
                        case "SELECT":
                            return (
                                <StyledControl key={field.name}>
                                    <label>
                                        {field.label}:
                                        <br />
                                        <Select
                                            onChange={changeInputHandler}
                                            name={field.name}
                                            type="select"
                                        >
                                            {field.options.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                    </label>
                                </StyledControl>
                            );
                        default:
                            return (
                                <p style={{ color: "red" }} key={field.name}>
                                    Ошибка загрузки поля
                                </p>
                            );
                    }
                })}
            </Grid>
            <div style={{ textAlign: "right" }}>
                <label htmlFor="viewExtendedFields">Показать все поля</label>
                <StyledCheckbox
                    checked={props.isShowExtendedFields}
                    onChange={showExtendedFields}
                    type="checkbox"
                />
                <DangerButton onClick={clearSearchForm} type="button">
                    Очистить
                </DangerButton>
                <SuccessButton type="submit">Поиск</SuccessButton>
            </div>
        </StyledSearchForm>
    );
};

const mapStateToProps = (state) => {
    let fetchedFields = state.registrySearchableFields.fetchedSearchableRegistryFields;
    let mainFields = fetchedFields.filter((field) =>
        ["registerNumber", "trader.INN", "agency.agencyCode", "dateFrom", "dateTo", "products.translations.genericName"].includes(field.name)
    );

    return {
        mainFields,
        fetchedFields: state.registrySearchableFields.fetchedSearchableRegistryFields,
        isShowExtendedFields: state.app.showExtendedFields,
        registryId: state.registrySearchableFields.registryId,
        currentPage: state.registrySearchableFields.currentPage,
        perPage: state.registrySearchableFields.perPage,
        totalCount: state.registrySearchableFields.totalCount,
    };
};

const mapDispatchToProps = {
    searchRegistries,
    hideRegistries,
    showExtendedFields: viewExtendedFields,
    setSearchFormState,
    setCurrentPage,
    fetchRegistrySearchableFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);

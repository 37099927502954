import styled from 'styled-components'
import React from "react"


const StyledInfoButton = styled.button`
  background-color: #002d3f;
  color: white;
  border: none;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  display: ${props => props.dislpay || 'inline-block'};
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #cbcbcb;
    color: #8d8d8d;
  }
`
export const InfoButton = (props) => {
    return <StyledInfoButton {...props} />
}


const StyledSuccessButton = styled.button`
  background-color: #719aff;
  border: none;
  color: white;
  padding: 0.8rem;
  margin: 0.5rem;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  display: ${props => props.dislpay || 'inline-block'};
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #cbcbcb;
    color: #8d8d8d;
  }
`
export const SuccessButton = (props) => {
    return <StyledSuccessButton {...props} />
}

export const StyledDangerButton = styled.button`
  background-color: #ff7182;
  border: none;
  color: white;
  padding: 0.8rem;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  display: ${props => props.dislpay || 'inline-block'};
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #cbcbcb;
    color: #8d8d8d;
  }
`
export const DangerButton = (props) => {
    return <StyledDangerButton {...props} />
}

export const Input = styled.input`
  //font-size: 18px;
  padding: 8px;
  margin: 4px;
  background: #ffffff;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  animation: fade-out 1s 0s linear;

  ::placeholder {
    color: #7e7e7e;
  }

  :focus {
    border: 0;
  }
`
export const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin: 4px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: none;
  border-radius: 8px;
`

export const StyledSearchForm = styled.form`
  font-size: 12px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 15px;
  border: solid 1px rgb(179, 204, 255);
  padding: 25px;
  background-color: #f5f9ff;
  animation: drop-down 1s 0s  linear;
  @keyframes drop-down{
    from{
      height: auto;
    }
    to{
      height: 100%;
    }
  }

`

export const StyledControl = styled.div`
  padding: 0.5rem;
  input{
    margin-top: 15px;
  }
  select{
    margin-top: 15px;
  }
`
export const StyledHr = styled.hr`
  border: 0;
  height: 0;
  padding: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`

export const StyledUl = styled.ul`
  padding: 1rem;

  li {
    padding: 1rem;
  }
`

export const StyledResultsWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  text-align: center;
  background-color: #f5f9ff;
  border-radius: 15px;
  border: solid 1px rgb(179, 204, 255);
  animation: fade-out 1s 0s linear;
`

export const StyledResultsTable = styled.table`
  //box-shadow: inset 0 5px 10px rgba(128, 128, 128, 0.25);
  overflow-x: scroll;
  white-space: nowrap;
  justify-content: center;
  text-align: center;
  background-color: #f5f9ff;
  border-radius: 15px;
  border: solid 1px rgb(179, 204, 255);
  border-collapse: collapse;
  animation: fade-out 0.5s 0s linear;
  width: 100%;

  @media (max-width: 500px) {
    overflow-x: scroll;
  }
  table{
    border-radius: 15px;
    margin: 0 auto;
  }
  td {
    //font-size: 14px;
    padding: 1px;
    border: rgba(0, 178, 255, 0.29) 1px solid;
  }

  th {
    background-color: #719aff;
    color: white;
    padding: 4px;
    border: rgba(127, 208, 253, 0.8) 1px solid;

    &:hover {
      cursor: pointer;
      background-color: #336fff;
    }
  }

  tr {
    &:hover {
      background-color: rgba(168, 204, 255, 0.24);
    }
  }
  .wrap {
    white-space: pre-wrap;
    max-width: 300px;
  }
`

export const StyledRow = styled.tr`
word-wrap: break-word;
  text-align: center;
  width: 100%;
  border: 1px;
  font-size: 14px;
  margin: 0 auto;
  border-collapse: collapse;
  padding: 1rem;
  img{
    float: right;
  }
  th {
    border: none;

  }

  .wrap {
    white-space: pre-wrap;
  }
  
  .link{
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
`

export const StyledCheckbox = styled.input`
margin: 1rem;
`
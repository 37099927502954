import React from "react"
import {connect, useDispatch} from "react-redux"
import {getDocumentInfoById} from "../redux/actions"
import {useNavigate, useParams} from "react-router-dom"
import {StyledRow, SuccessButton} from "./commonStyledComponents/StyledFormWidgets"

function SearchedResult(object) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = useParams();
    function showDocumentInfo(documentId) {
        dispatch(getDocumentInfoById(documentId))
        navigate(`/registry/${documentId}/${id}`)
    }
    return (
        <StyledRow>
            <td>
                    <SuccessButton
                        onClick={() => showDocumentInfo(object.object.documentId)}
                        className="fancy-button medium rounded blueRiver zoom">Детали
                    </SuccessButton>
            </td>
            <td className="wrap">{object.object.agency}</td>
            <td className="wrap">{object.object.trader}</td>
            <td className="test">{object.object.registerNumber}</td>
            <td>{object.object.dateFrom}</td>
            <td>{object.object.status === '1' ? "Действует" : `${object.object.status}`}</td>
        </StyledRow>
    )
}

const mapDispatchToProps = {
    getDocumentInfoById
}
export default connect(null, mapDispatchToProps)(SearchedResult)
import React, { Fragment } from "react";
import { StyledResultsTable, StyledRow } from "./commonStyledComponents/StyledFormWidgets";

function Products(props) {
    if (!props.products.length) {
        return <div style={{ marginTop: 30, marginBottom: 30 }}>Товаров нет</div>;
    }
    return (
        <StyledResultsTable>
            <table style={{ marginTop: 20, marginBottom: 20, maxWidth: '400px' }}>
                <tbody>
                    <StyledRow>
                        <th colSpan="2">
                            Товары
                        </th>
                    </StyledRow>
                    {props.products.map((product) => (
                        <Fragment key={product.id}>
                            <StyledRow>
                                <th colSpan="2">
                                    {product.additionalInformation}
                                </th>
                            </StyledRow>
                            
                            <StyledRow>
                                <td>Полное наименование продукции</td>
                                <td style={{textWrap: 'wrap'}}>{product.translations.ru?.commercialDescription}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Наименование ТН ВЭД</td>
                                <td>{product.translations.ru?.name}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Код ТН ВЭД для единицы продукта</td>
                                <td>{product.hscode}</td>
                            </StyledRow>
                            {/* <StyledRow>
                                <td>Кол-во в доп. ед.из.</td>
                                <td>{product.additionalUnitCount}</td>
                            </StyledRow>
                            <StyledRow>
                                <td>Код доп. ед.из.</td>
                                <td>{product.additionalUnitCode}</td>
                            </StyledRow> */}
                        </Fragment>
                    ))}
                </tbody>
            </table>
        </StyledResultsTable>
    );
}

export default Products;

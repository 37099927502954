import {FETCH_REGISTRIES} from "../types"

const initialState = {
    registries: [],
    fetchedRegistries: []
}

const filterRegistry = (data) => {
    const filteredRegistries =  data.filter(el => [38, 33].includes(el.id))
    localStorage.setItem('registries', JSON.stringify(filteredRegistries))
    return filteredRegistries
}

export const registriesReducer = (state = initialState, action) => {
    switch (action.type){
        case FETCH_REGISTRIES:
            return {...state, fetchedRegistries: filterRegistry(action.payload)}
        default: return state
    }
}
import {API_KEY, API_LOCATION, SEARCH_API_KEY} from "./constants"

class API {
    location = API_LOCATION
    headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": API_KEY
    }
    searchHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": SEARCH_API_KEY
    }

    async getRegistries(locale, limit) {
        return fetch(
            this.location + "registries?" + new URLSearchParams({
                locale: locale,
                limit: 10,
            }),
            {
                method: "GET",
                headers: this.headers
            }).then(res => res.json())
    }

    async getRegistryFields(registryId, locale) {
        try {
            return fetch(
                this.location + "modules/1/registries/" + parseInt(registryId) + "/fields?" + new URLSearchParams({
                    locale: locale,
                }),
                {
                    method: "GET",
                    headers: this.headers,
                }).then(res => res.json())

        }
        catch (e){

        }
    }

    async searchRegistryByFields(registryId, locale, data, offset= 0, limit= 10) {
        return fetch(
            this.location + "registries/" + registryId + "/search?" + new URLSearchParams({
                locale: locale,
                offset: offset,
                limit: limit,
            }),
            {
                method: "POST",
                headers: this.searchHeaders,
                body: JSON.stringify(data),
            },
        ).then(res => res.json())
    }

    async getDocumentInfoById(documentId, locale) {
        return fetch(
            this.location + "registries?" + new URLSearchParams({
                locale: locale,
                documentId: documentId
            }),
            {
                method: "GET",
                headers: this.headers,
            }).then(res => res.json())
    }

}

export default new API()

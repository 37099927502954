import {
    HIDE_LOADER,
    SET_SERVER_ERRORS,
    SHOW_LOADER,
    UNSET_SERVER_ERRORS,
    VIEW_EXTENDED_FIELDS
} from "../types"

const initialState = {
    loading: false,
    showExtendedFields: false,
    hasServerErrors: false,
}

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOADER:
            return {...state, loading: true}
        case HIDE_LOADER:
            return {...state, loading: false}
        case VIEW_EXTENDED_FIELDS:
            return {...state, showExtendedFields: !state.showExtendedFields}
        case SET_SERVER_ERRORS:
            return {...state, hasServerErrors: true }
        case UNSET_SERVER_ERRORS:
            return {...state, hasServerErrors: false }
        default:
            return state

    }
}
import React, {useEffect} from 'react'
import {connect, useDispatch, useSelector} from "react-redux"
import {StyledPaginationWrapper} from "./commonStyledComponents/StyledPagination"
import {searchRegistries, setCurrentPage} from "../redux/actions"
import {createPages} from "./pagesCreator"
import {useParams} from "react-router-dom"



function Pagination(props) {
    const currentPage = useSelector(state => state.registrySearchableFields.currentPage)
    const registryId = useSelector(state => state.registrySearchableFields.registryId)
    const perPage = useSelector(state => state.registrySearchableFields.perPage)
    const totalCount = useSelector(state => state.registrySearchableFields.totalCount)
    const searchFormState = useSelector(state => state.registrySearchableFields.searchFormState)
    const {id} = useParams()


    const pagesCount = Math.ceil(totalCount / perPage)
    const pages = []
    const dispatch = useDispatch()

    createPages(pages, pagesCount, currentPage)
    useEffect(() => {
        let offset = 0
        if(currentPage === 1 && offset === 0){
            offset = 0
        }else {
            offset = ((currentPage - 1)  * perPage)
        }
        // debugger
        dispatch(searchRegistries((registryId !== null ? registryId : parseInt(id)), searchFormState, offset, perPage))
    }, [registryId, currentPage])

    return (

            <StyledPaginationWrapper>
                <div className="pgn pgn-default">
                    <div className="pages">
                        {pages.map((page, index) => <span
                            key={index}
                            onClick={() => dispatch(setCurrentPage(page))}
                            className={currentPage === page ? "page page-btn active" : "page page-btn"}
                        >
                            {page}
                        </span>)}
                    </div>
                </div>
            </StyledPaginationWrapper>
    )
}

const mapDispatchToProps = {
    setCurrentPage,
    searchRegistries
}
export default connect(null, mapDispatchToProps)(Pagination)
export const FETCH_REGISTRIES = 'FETCH_REGISTRIES'
export const FETCH_REGISTRY_FIELDS = "FETCH_REGISTRY_FIELDS"
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const SEARCH_REGISTRY = 'SEARCH_REGISTRY'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_SEARCH_FORM_STATE = 'SET_SEARCH_FORM_STATE'
export const SHOW_REGISTRIES = "SHOW_REGISTRIES"
export const HIDE_REGISTRIES = "HIDE_REGISTRIES"
export const GET_DOCUMENT_INFO = "GET_DOCUMENT_INFO"
export const VIEW_EXTENDED_FIELDS = "VIEW_EXTENDED_FIELDS"
export const SET_SERVER_ERRORS = "SET_SERVER_ERRORS"
export const UNSET_SERVER_ERRORS = "UNSET_SERVER_ERRORS"
export const SET_REGISTRY_ID = "SET_REGISTRY_ID"
export const SET_REGISTRY_LABEL = "SET_REGISTRY_LABEL"
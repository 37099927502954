import React from "react"
import styled from "styled-components"
import {fetchRegistrySearchableFields} from "../redux/actions"
import {useDispatch, connect} from "react-redux"
import SearchForm from "./SearchForm"

const StyledRegistry = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  border: solid 1px #cdd9ed;
  text-align: center;
  margin: 1rem;
  justify-content: center;
  transition: 0.5s;
  text-transform: uppercase;

  &:hover {
    border: solid 1px #99aede;
    cursor: pointer;
  }
`


function Registry(object) {
    const dispatch = useDispatch()
    return (
        <>
            <StyledRegistry
                data-id={object.object.name}
                onClick={() => dispatch(
                    fetchRegistrySearchableFields(
                        parseInt(object.object.name),
                        object.object.label
                    )
                )}
            >
                {object.object.label}
            </StyledRegistry>
        </>
    )
}


export default connect(null, null)(Registry)
import {StyledHr, StyledUl, SuccessButton} from "../commonStyledComponents/StyledFormWidgets"
import {NavLink} from "react-router-dom"
import {fetchRegistries} from "../../redux/actions"
import {connect, useDispatch} from "react-redux"

function HomePage() {
    const dispatch = useDispatch()
    return (
        <>
            <h1>Центр единого окна</h1>
            <StyledHr/>
            <p>Основными целями «Единого Окна» являются оптимизация административных процедур и снижение административных барьеров при
                получении необходимых для таможенного оформления документов. Внедрение «Единого Окна» позволит достичь также следующие цели:
            </p>
            <StyledUl>
                <li>
                    – обеспечение предсказуемости и законности всех регуляторных требований вовлеченных органов и организаций, прозрачности,
                    централизованного доступа к информации в сфере регулирования внешней торговли;
                </li>
                <li>
                    – обеспечение эффективного взаимодействия государственных органов с участниками внешнеэкономической деятельности (ВЭД);
                </li>
                <li>
                    – сокращение временных и финансовых затрат, необходимых на получение соответствующих документов.
                </li>
            </StyledUl>

            <NavLink
                onClick={() => dispatch(fetchRegistries())}
                to="/registries">
                <SuccessButton>
                    Перейти к реестрам
                </SuccessButton>
            </NavLink>

        </>
    )
}
export default connect(null, null)(HomePage)